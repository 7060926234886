const currentYear = new Date().getFullYear();
const footerLinks = [
  { title: 'Blog', href: 'https://blog.vuukle.com' },
  { title: 'Help', href: 'https://docs.vuukle.com' },
  {
    title: 'Privacy Policy',
    href: 'https://news.vuukle.com/blog/privacy-notice',
  },
  {
    title: 'Terms',
    href: 'https://news.vuukle.com/blog/terms-of-service',
  },
  {
    title: 'Cookie Policy',
    href: 'https://news.vuukle.com/blog/cookie-notice',
  },
];

const Footer = (props) => (
  <footer
    className="footer justify-content-start d-flex footer-fixed"
    {...props}
    style={{ marginTop: 'auto', zIndex: 1 }}
  >
    <div className="mr-2">
      <nav className="nav">
        {footerLinks.map(({ title, href }) => (
          <a
            href={href}
            className="nav-link tx-medium"
            target="_blank"
            rel="nofollow noreferrer"
            key={title}
            style={{ outline: 'none' }}
          >
            {title}
          </a>
        ))}
      </nav>
    </div>
    <div className="d-none d-lg-block">
      <span>
        &copy; {currentYear} Vuukle {process.env.VERSION}{' '}
      </span>
    </div>
  </footer>
);

export default Footer;
