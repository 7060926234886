import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { Avatar, toaster } from 'evergreen-ui';

import AsideItemDropdown from './NavDropdown';
import AsideLink from './AsideLink';
import SiteListDropdown from '@/components/SiteListDropdown';
import { updateHost } from '@/services/actions/filter';
import { removeToken, setAsideOpen } from '@/services/actions/session';
import { setRevenueAdUnitList } from '@/services/actions/revenue';
import { updateSearchTypeAndParam } from '@/services/actions/moderation.js';
import LogoWithShadow, {
  MobileLogoWithShadow,
} from '@/components/LogoWithShadowSVG';
import {
  AnalyticsIcon,
  LogoutIcon,
  HelpIcon,
  adImage,
  RealtimeIcon,
  BillingIcon,
  IntegrationIcon,
  ModerationIcon,
  QuizModerationIcon,
  ReportsIcon,
  RevenueIcon,
  SettingsIcon,
  PolicyCenterIcon,
  SitesListIcon,
  WebPushIcon,
  GoogleAdManagerIcon,
  QuizzlyIcon,
  OverviewIcon,
  ByDateIcon,
  ByDomainAndDateIcon,
  ByDomainIcon,
  ByAdUnitIcon,
  BuilderIcon,
  ShareBarIcon,
  EmoteSettingsIcon,
  CommentsIcon,
  PaymentHistoryIcon,
  AdvancedReportIcon,
  ProfileSettingsIcon,
  UserManagementIcon,
  DocumentationIcon,
  TrafficIcon,
  NewsletterIcon,
  BookmarkIcon,
} from '@/components/LeftBarIcons';
import Link from 'next/link';
import { blockedQuizPagesApiKeyList } from '@/utils';
import AdsTxt from '@/components/AdsTxt';
import SettingsSearcher from '@/components/SettingsSearcher';

const widgetsLinks = [
  {
    name: 'Analytics',
    Icon: AnalyticsIcon,
    url: '/analytics',
    items: [
      { name: 'Traffic', Icon: TrafficIcon, url: '/analytics/traffic' },
      { name: 'Comment', Icon: CommentsIcon, url: '/analytics/comments' },
      { name: 'React', Icon: EmoteSettingsIcon, url: '/analytics/emotes' },
      { name: 'Share', Icon: ShareBarIcon, url: '/analytics/shares' },
      { name: 'Audio', Icon: HelpIcon, url: '/analytics/audio' },
      {
        name: 'Newsletters',
        Icon: NewsletterIcon,
        url: '/analytics/newsletters',
      },
      {
        name: 'Bookmarks',
        Icon: BookmarkIcon,
        url: '/analytics/bookmarks',
      },
    ],
  },
];

const billingLinks = [
  {
    name: 'Billing',
    Icon: BillingIcon,
    url: '/billing?by=paymentdetails',
    items: [
      {
        name: 'Payment Details',
        Icon: BillingIcon,
        url: '/billing?by=paymentdetails',
      },
      {
        name: 'Payment History',
        Icon: PaymentHistoryIcon,
        url: '/billing?by=paymenthistory',
      },
    ],
  },
];

const integrationLinks = [
  {
    name: 'Integration',
    Icon: IntegrationIcon,
    url: '/integration',
    items: [
      { name: 'Builder', Icon: BuilderIcon, url: '/builder' },
      { name: 'Integration', Icon: IntegrationIcon, url: '/integration' },
    ],
  },
];

const supportLinks = [
  {
    name: 'Help',
    Icon: HelpIcon,
    url: 'https://docs.vuukle.com',
    items: [
      {
        name: 'Documentation',
        Icon: DocumentationIcon,
        url: 'https://docs.vuukle.com',
      },
      { name: 'Support', Icon: HelpIcon, url: 'mailto:support@vuukle.com' },
      { name: 'Chat with us', Icon: CommentsIcon, click: true, url: '' },
    ],
  },
];

const Aside = (props) => {
  const sessionStore = useSelector(({ session }) => session);
  const { sites, daysLeft, expireTimeIsSet, user, apiKey } = sessionStore;
  const { host, dateRange } = useSelector(({ filter }) => filter);

  const [openedDropdown, setOpenedDropdown] = useState('');
  const [asidePinned, setAsidePinned] = useState(false);
  const [minimized, setMinimized] = useState(window.innerWidth < 800);
  const [asideHovered, setAsideHovered] = useState(false);
  const [addPadding, setAddPadding] = useState(false);
  const [addTimeout, setAddTimeout] = useState(true);

  const formattedFrom = dateRange
    ? dayjs(dateRange[0]).format('YYYY-MM-DD')
    : dayjs().subtract(1, 'weeks').format('YYYY-MM-DD');
  const formattedTo = dateRange
    ? dayjs(dateRange[1]).format('YYYY-MM-DD')
    : dayjs().format('YYYY-MM-DD');
  const dispatch = useDispatch();
  const router = useRouter();

  const dashboardItems = useMemo(
    () => [
      { name: 'Sites List', Icon: SitesListIcon, url: '/' },
      // { name: 'Realtime', Icon: RealtimeIcon, url: '/realtime' },
      // {
      //   name: 'Web Push',
      //   Icon: WebPushIcon,
      //   url: '/cloud-messaging',
      // },
      { name: 'Reports', Icon: ReportsIcon, url: '/reports' },
      {
        // name: 'Google MCM/GAM',
        name: 'Google GAM',
        Icon: GoogleAdManagerIcon,
        // url: '/google-mcm-and-gam',
        url: '/google-gam',
      },
      {
        name: 'Revenue',
        Icon: RevenueIcon,
        url: '/revenue',
        items: [
          {
            name: 'Overview',
            Icon: OverviewIcon,
            url: '/revenue?by=domain&host=',
          },
          { name: 'By Domain', Icon: ByDomainIcon, url: '/revenue?by=domain' },
          { name: 'By Date', Icon: ByDateIcon, url: '/revenue?by=date' },
          {
            name: 'By Domain & Date',
            Icon: ByDomainAndDateIcon,
            url: '/revenue?by=domainanddate',
          },
          {
            name: 'By Ad Units',
            Icon: ByAdUnitIcon,
            url: '/revenue?by=adunits',
          },
          {
            name: 'Advance report',
            Icon: AdvancedReportIcon,
            url: '/revenue?by=advancereport',
          },
        ],
      },
      { name: 'Policy Center', Icon: PolicyCenterIcon, url: '/policy-center' },
      { name: 'Moderation', Icon: ModerationIcon, url: '/moderation' },
      {
        name: 'Settings',
        Icon: SettingsIcon,
        url: '/settings',
        items: [
          {
            name: 'Profile Settings',
            Icon: ProfileSettingsIcon,
            url: `/settings/?tab=0&subtab=&host=${router?.query?.host || ''}`,
          },
          {
            name: 'Comment Widget',
            Icon: CommentsIcon,
            url: `/settings/?tab=1&subtab=&host=${router?.query?.host || ''}`,
          },
          {
            name: 'Emote Settings',
            Icon: EmoteSettingsIcon,
            url: `/settings/?tab=3&subtab=&host=${router?.query?.host || ''}`,
          },
          {
            name: 'Sharebar Settings',
            Icon: ShareBarIcon,
            url: `/settings/?tab=4&subtab=&host=${router?.query?.host || ''}`,
          },
          {
            name: 'User Management',
            Icon: UserManagementIcon,
            url: `/settings/?tab=2&subtab=&host=`,
          },
        ],
      },
    ],
    [host],
  );

  let granted = false;

  useEffect(() => {
    dispatch(setAsideOpen(minimized));
  }, [minimized]);

  useEffect(() => {
    if (
      dashboardItems &&
      !blockedQuizPagesApiKeyList.includes(apiKey) &&
      !granted
    ) {
      dashboardItems.push({
        name: 'Quizzly.AI',
        Icon: QuizzlyIcon,
        url: `/brand-survey/?tab=1&host=${router?.query?.host || sites[0]}`,
      });
      dashboardItems.push({
        name: 'Quiz Moderation',
        Icon: QuizModerationIcon,
        url: '/quiz-moderation',
      });
      if (
        widgetsLinks[0].items[widgetsLinks[0].items.length - 1].name !== 'Quiz'
      ) {
        widgetsLinks[0].items.push({
          name: 'Quiz',
          Icon: QuizModerationIcon,
          url: '/analytics/quiz',
        });
      }
      dashboardItems[6].items.push({
        name: 'Quiz Widget',
        Icon: QuizModerationIcon,
        url: `/settings/?tab=5&subtab=&host=${router?.query?.host || ''}`,
      });
      granted = true;
    }
  }, [dashboardItems]);

  useEffect(() => {
    setTimeout(() => {
      const h4Tags = document.getElementsByTagName('h4');
      const searchText =
        'Thanks for choosing Vuukle, please email us on ad@vuukle.com to learn about our ad monetization service';
      let found;
      for (var i = 0; i < h4Tags.length; i++) {
        if (h4Tags[i].textContent == searchText) {
          found = h4Tags[i];
          break;
        }
      }
      const elem = document.createElement('h4');
      const tag1 = document.createElement('span');
      tag1.innerText = 'Thanks for choosing Vuukle, please email us on';
      const mail = document.createElement('a');
      mail.innerText = ' ad@vuukle.com ';
      mail.href = 'mailto:ad@vuukle.com';
      const tag2 = document.createElement('span');
      tag2.innerText = 'to learn about our ad monetization service';
      elem.appendChild(tag1);
      elem.appendChild(mail);
      elem.appendChild(tag2);
      found && found.replace && found.replace(elem);
    });
  });

  useEffect(() => {
    if (router.pathname !== '/') {
      if (expireTimeIsSet && daysLeft > 0 && router.pathname === '/') {
        toaster.closeAll();
        toaster.notify(
          `Thanks for choosing Vuukle, please email us on ad@vuukle.com to learn about our ad monetization service`,
          {
            duration: 10,
            id: 'expired-notify',
          },
        );
      } else if (expireTimeIsSet && daysLeft < 0) {
        toaster.notify(
          `Thanks for choosing Vuukle, please email us on ad@vuukle.com to learn about our ad monetization service`,
          {
            duration: 1000000,
            id: 'expired-danger',
          },
        );
      }
      setTimeout(() => {
        const notificationWrapper = document.querySelector(
          '[data-evergreen-toaster-container]',
        );
        if (notificationWrapper) {
          const dangerNotifification =
            notificationWrapper.querySelector('.css-1xebggx');
          if (dangerNotifification) {
            const closeButton = dangerNotifification.querySelector('button');
            closeButton.style.display = 'none';
            setAddPadding(true);
          }
        }
      }, 100);
    }
    return () => {
      toaster.closeAll();
      setAddPadding(false);
    };
  }, [expireTimeIsSet]);

  useEffect(() => {
    if (minimized) {
      setTimeout(() => {
        setAddTimeout(true);
      }, 750);
    } else {
      setAddTimeout(false);
    }
  }, [minimized]);

  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://cdn.vuukle.com/platform.js"]',
      )
    ) {
      const scriptTagForConfig = document.createElement('script');
      const scriptTagForPlatform = document.createElement('script');

      scriptTagForConfig.innerHTML = `
        var VUUKLE_CONFIG = {
          apiKey: 'ead41e46-a5fd-11e2-bc97-bc764e0492cc',
          articleId: 1,
          host: 'dash.vuukle.com'
        }
      `;
      scriptTagForPlatform.src = 'https://cdn.vuukle.com/platform.js';
      scriptTagForPlatform.async = true;

      document.body.appendChild(scriptTagForConfig);
      document.body.appendChild(scriptTagForPlatform);
    }
  }, []);

  const logOut = () => {
    dispatch(updateHost(''));
    dispatch(removeToken());
    dispatch(setRevenueAdUnitList([]));
    dispatch(updateSearchTypeAndParam('', ''));
  };

  const renderDashboardMenuItems = (items) =>
    items.map(({ name, Icon, url, items }, index) => {
      if (items && items.length > 0) {
        if (name === 'Revenue') {
          if (sites.length > 0) {
            items[0] = {
              name: 'Overview',
              Icon: OverviewIcon,
              url: `/revenue?by=domain&host=&from=${
                router.query.from || formattedFrom
              }&to=${router.query.to || formattedTo}`,
            };
            items[1] = {
              name: 'By Domain',
              Icon: ByDomainIcon,
              url: `/revenue?by=domain&host=${host || sites[0]}&from=${
                router.query.from || formattedFrom
              }&to=${router.query.to || formattedTo}`,
            };
            items[2] = {
              name: 'By Date',
              Icon: ByDateIcon,
              url: `/revenue?by=date&host=${host || sites[0]}&from=${
                router.query.from || formattedFrom
              }&to=${router.query.to || formattedTo}`,
            };
            items[3] = {
              name: 'By Domain & Date',
              Icon: ByDomainAndDateIcon,
              url: `/revenue?by=domainanddate&host=${host || sites[0]}&from=${
                router.query.from || formattedFrom
              }&to=${router.query.to || formattedTo}`,
            };
            items[4] = {
              name: 'By Ad Units',
              Icon: adImage,
              url: `/revenue?by=adunits&host=${host || sites[0]}&from=${
                router.query.from || formattedFrom
              }&to=${router.query.to || formattedTo}`,
            };
            items[5] = {
              name: 'Advance report',
              Icon: AdvancedReportIcon,
              url: `/revenue?by=advancereport&host=${host || sites[0]}&from=${
                router.query.from || formattedFrom
              }&to=${router.query.to || formattedTo}`,
            };
          }

          items[0].onClick = clearHost;
        }
        return (
          <AsideItemDropdown
            minimized={minimized}
            name={name}
            Icon={Icon}
            subItems={items}
            key={index}
            setAsideHovered={setAsideHovered}
            setMinimized={setMinimized}
            url={url}
            setOpenedDropdown={setOpenedDropdown}
            openedDropdown={openedDropdown}
          />
        );
      } else {
        return (
          <AsideLink
            currentOnline={props?.currentOnline?.OnlineCount}
            name={name}
            Icon={Icon}
            url={url}
            key={index}
          />
        );
      }
    });

  const asideClassName = classNames('aside', 'aside-fixed', {
    minimize: minimized,
    maximize: minimized && asideHovered,
  });

  // const handleToggleClick = (e) => {
  //   if (document.body.clientWidth > 800) {
  //     e.preventDefault();
  //     setMinimized(false);
  //   }
  // };
  // const handleMouseLeave = (e) => {
  //   setMinimized(true);
  // };

  const handleClickForMobile = (e) => {
    setMinimized(!minimized);
  };

  const clearHost = () => {
    dispatch(updateHost(''));
  };

  return (
    <aside
      className={`${asideClassName} newaside ${
        !minimized ? 'aside-opened' : ''
      }`}
    >
      <div className="aside-header">
        <Link href="/" className="aside-logo-mini">
          <MobileLogoWithShadow />
        </Link>
        <Link href="/" className="aside-logo">
          <LogoWithShadow />
        </Link>
        <div
          className="aside-menu-link none-pc-visible"
          onClick={handleClickForMobile}
        />
      </div>
      <div className="aside-pinned-button">
        <button
          onClick={() => {
            setAsidePinned(!asidePinned);
            setMinimized(!minimized);
          }}
        >
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ transform: `rotate(${asidePinned ? '270deg' : '90deg'})` }}
          />
        </button>
      </div>
      <div
        className="aside-body firefoxScrollBar outline-none"
        style={
          minimized && addTimeout
            ? { width: '60px' }
            : { width: '100%', overflowY: 'auto' }
        }
      >
        {+window.innerWidth <= 1020 ? (
          <div className="d-flex justify-content-between sidebar-mobile flex-wrap">
            <AdsTxt showWhenNoMissingLines={true} />
            <SettingsSearcher />
          </div>
        ) : (
          <></>
        )}
        {router.pathname !== '/' && (
          <div className="d-lg-none">
            <SiteListDropdown
              excludeAllSites={router.pathname === '/settings'}
              fromMenu={true}
            />
          </div>
        )}
        <ul
          className={`nav nav-aside ${
            minimized && addTimeout
              ? 'h-100 overflow-auto firefoxScrollBar'
              : ''
          } ${addPadding ? 'pd-b-60' : ''}`}
        >
          <div
            className={`d-flex w-100 flex-row ${
              minimized && 'justify-content-center'
            } align-items-center mg-b-15`}
          >
            <Avatar
              src={`${user.pictureUrl}?q=${Math.random()}${Math.random()}`}
              name={user.name}
              size={48}
              color={user.pictureUrl ? 'white' : 'automatic'}
            />
            <span
              className={`ml-2 text-truncate ${
                !minimized ? 'd-block' : 'd-none'
              }`}
            >
              <span className="new-aside-mail">{user.email}</span>
              <span className="text-truncate d-block tx-uppercase new-aside-host">
                {host ? host : user.name}
              </span>
            </span>
          </div>
          {renderDashboardMenuItems(dashboardItems)}
          {renderDashboardMenuItems(widgetsLinks)}
          {renderDashboardMenuItems(billingLinks)}
          {renderDashboardMenuItems(integrationLinks)}
          {renderDashboardMenuItems(supportLinks)}
          <li className="nav-item">
            <a onClick={logOut} className="nav-link">
              {LogoutIcon()}
              <span className="lato-family-font color-logout">Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Aside;
