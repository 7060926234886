import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Li = styled('div')`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
`;

const LineNumbers = ({ content, className }) => {
  const numberOfLines = content.split('\n').filter(Boolean);

  const ads = useSelector(({ settingsPage }) => ({
    lines: settingsPage.profile.adsMissingLines,
  }));

  if (!numberOfLines.length) {
    return <></>;
  }
  return (
    <span className={`custom-line-numbers ${className}`}>
      <ul>
        {numberOfLines.map(
          (line, index) =>
            ads.lines.lines && (
              <Li
                key={index + 1}
                style={{
                  backgroundColor: ads.lines.lines.includes(line) && '#fef0ef',
                }}
              >
                <FontAwesomeIcon
                  icon={
                    !ads.lines.lines.includes(line)
                      ? faCheckCircle
                      : faExclamationCircle
                  }
                  color={ads.lines.lines.includes(line) ? 'red' : ''}
                  style={{ marginRight: '5px' }}
                />
                {index + 1}
              </Li>
            ),
        )}
      </ul>
    </span>
  );
};

const StyledLineNumbers = styled(LineNumbers)`
  text-align: right;
  background: #f2feef;
  color: #5dc79e;
  border-radius: 2px 0 0 2px;
  min-width: 65px;
  font-size: 13px;
  line-height: 1.65;

  & ul {
    padding: 0;
    width: 100%;
    margin-bottom: 0;
  }
`;

export default StyledLineNumbers;
