import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router, { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import LoadingOverlay from 'react-loading-overlay';

// This library issues an error on an optional prop which is why we're disabling the propType validation
LoadingOverlay.propTypes = undefined;

import Loader from '@/components/Loader';
import { saveToken, removeToken, saveUrl } from '@/services/actions/session';

export const AuthProtectedPage = (Child, loaderEnabled = true) => {
  const PageWrapper = (props) => {
    const sessionStore = useSelector(({ session }) => session);
    const { isGoogleAuth, loading, error } = sessionStore;

    const dispatch = useDispatch();
    const router = useRouter();

    const token = Cookies.get('dash_token');
    const another_token = Cookies.get('vuukle_token');

    useEffect(() => {
      if (!token && another_token) {
        Cookies.set('dash_token', another_token);
      }
    }, [another_token, token]);

    useEffect(() => {
      if (!token) {
        let searchParams = window.location.search;
        if (window.location.pathname.includes('revenue')) {
          searchParams = new URLSearchParams(window.location.search);
          const paramsToCheck = [
            'adUnit',
            'device',
            'category',
            'graph',
            'recall',
          ];
          paramsToCheck.forEach((param) => {
            if (searchParams.get(param)) {
              searchParams.delete(param);
            }
          });
          searchParams = '?' + searchParams.toString();
        }
        dispatch(saveUrl(window.location.pathname + searchParams));
      }
    }, []);

    useEffect(() => {
      // If user has no token
      if (!token) {
        Router.replace(
          `/login${
            router.asPath && router.asPath.includes('utm_') ? router.asPath : ''
          }`,
        );
      }

      // if token exists but not in the store
      if (token && !sessionStore.token && !isGoogleAuth) {
        dispatch(saveToken(token));
      }
    }, [token]);

    // If we have changes in our site then we need to redirect
    useEffect(() => {
      if (error) {
        dispatch(removeToken());
        dispatch(saveUrl(window.location.href));
        Router.replace(
          `/login${
            router.asPath && router.asPath.includes('utm_') ? router.asPath : ''
          }`,
        );
      }
    }, [error]);

    // If token exists and matches cookie
    if (
      sessionStore.token &&
      token === sessionStore.token &&
      sessionStore.apiKey
    ) {
      return (
        <LoadingOverlay
          className="loading-overlay-height"
          active={loading && loaderEnabled}
          spinner={<Loader />}
        >
          <Child {...props} isReady={loading && loaderEnabled} />
        </LoadingOverlay>
      );
    }

    return <></>;
  };

  return PageWrapper;
};

export default AuthProtectedPage;
