const LogoWithShadow = ({ className = '' }) => (
  <svg
    width="112"
    height="31"
    className={className}
    viewBox="0 0 112 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5503 22.2073H35.9716L44.9413 1.55912H40.8478L37.4164 9.44516C36.6338 11.221 35.8513 14.0805 35.8513 14.0805C35.8513 14.0805 35.0386 11.1909 34.256 9.44516L30.7343 1.55912H26.2195L35.5503 22.2073ZM49.1618 22.2976C50.9376 22.2976 52.4125 21.4548 53.1951 19.9197L53.1048 22.0267H57.1683V9.20432H53.1048V16.067C53.1048 17.6924 52.2018 18.6255 50.5764 18.6255C49.1317 18.6255 48.4393 17.7827 48.4393 16.3379V9.20432H44.376V16.6088C44.376 20.2508 46.3625 22.2976 49.1618 22.2976ZM65.0638 22.2976C66.8398 22.2976 68.3146 21.4548 69.097 19.9197L69.0068 22.0267H73.0703V9.20432H69.0068V16.067C69.0068 17.6924 68.104 18.6255 66.4786 18.6255C65.0335 18.6255 64.3412 17.7827 64.3412 16.3379V9.20432H60.2783V16.6088C60.2783 20.2508 62.2644 22.2976 65.0638 22.2976ZM80.334 18.3245L81.4173 17.1807L84.6081 22.0267H89.3336L84.3068 14.4417L88.852 9.20432H84.337L80.1234 14.1407C80.2439 13.328 80.334 12.3347 80.334 11.3715V0.294922H76.2705V22.0267H80.334V18.3245ZM91.234 22.0267H95.2672V0.294922H91.234V22.0267ZM102.253 16.8496H111.975C112.276 12.3648 109.778 8.93345 105.173 8.93345C101.2 8.93345 98.0996 11.7026 98.0996 15.5854C98.0996 19.5285 101.05 22.2976 105.624 22.2976C108.303 22.2976 109.868 21.4849 111.103 20.4013L108.785 17.9031C108.153 18.3545 107.039 18.9265 105.564 18.9265C103.728 18.9265 102.645 18.174 102.253 16.8496ZM102.193 14.5018C102.524 12.9668 103.608 12.2444 105.203 12.2444C106.798 12.2444 107.701 13.0872 107.882 14.5018H102.193Z"
      fill="rgb(250, 204, 43)"
    />
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="rgb(250, 204, 43)"
    />
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="#4885ED"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M25.5755 9.87867C25.5755 5.27383 21.4118 1.54086 16.2758 1.54086C12.567 1.54086 9.36602 3.48788 7.87286 6.30384C9.40061 5.31771 11.2736 4.7348 13.2999 4.7348C18.4359 4.7348 22.5996 8.46772 22.5996 13.0726C22.5996 14.834 21.9888 16.4666 20.9491 17.8129C23.0318 18.8191 25.5755 19.1785 25.5755 19.1785C24.5321 18.135 24.1649 15.9919 24.0373 14.4701C25.0086 13.1533 25.5755 11.5757 25.5755 9.87867Z"
        fill="rgb(250, 204, 43)"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
        fill="#4885ED"
      />
    </g>
    <path
      d="M13.2994 19.027H13.5185L18.1828 8.28983H16.0541L14.2698 12.3906C13.8629 13.3141 13.4559 14.801 13.4559 14.801C13.4559 14.801 13.0333 13.2984 12.6263 12.3906L10.7951 8.28983H8.44733L13.2994 19.027Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="3.87286"
        y="1.54086"
        width="25.7027"
        height="25.6376"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="0"
        y="4.73557"
        width="26.5995"
        height="25.6375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default LogoWithShadow;

export const MobileLogoWithShadow = ({ className = '' }) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="rgb(250, 204, 43)"
    />
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="#4885ED"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M25.5755 9.87867C25.5755 5.27383 21.4118 1.54086 16.2758 1.54086C12.567 1.54086 9.36602 3.48788 7.87286 6.30384C9.40061 5.31771 11.2736 4.7348 13.2999 4.7348C18.4359 4.7348 22.5996 8.46772 22.5996 13.0726C22.5996 14.834 21.9888 16.4666 20.9491 17.8129C23.0318 18.8191 25.5755 19.1785 25.5755 19.1785C24.5321 18.135 24.1649 15.9919 24.0373 14.4701C25.0086 13.1533 25.5755 11.5757 25.5755 9.87867Z"
        fill="rgb(250, 204, 43)"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
        fill="#4885ED"
      />
    </g>
    <path
      d="M13.2994 19.027H13.5185L18.1828 8.28983H16.0541L14.2698 12.3906C13.8629 13.3141 13.4559 14.801 13.4559 14.801C13.4559 14.801 13.0333 13.2984 12.6263 12.3906L10.7951 8.28983H8.44733L13.2994 19.027Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="3.87286"
        y="1.54086"
        width="25.7027"
        height="25.6376"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="0"
        y="4.73557"
        width="26.5995"
        height="25.6375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const MobileLogo = ({ className = '' }) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="rgb(250, 204, 43)"
    />
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="#4885ED"
    />
    <path
      d="M25.5755 9.87867C25.5755 5.27383 21.4118 1.54086 16.2758 1.54086C12.567 1.54086 9.36602 3.48788 7.87286 6.30384C9.40061 5.31771 11.2736 4.7348 13.2999 4.7348C18.4359 4.7348 22.5996 8.46772 22.5996 13.0726C22.5996 14.834 21.9888 16.4666 20.9491 17.8129C23.0318 18.8191 25.5755 19.1785 25.5755 19.1785C24.5321 18.135 24.1649 15.9919 24.0373 14.4701C25.0086 13.1533 25.5755 11.5757 25.5755 9.87867Z"
      fill="rgb(250, 204, 43)"
    />
    <path
      d="M13.2998 4.73557C8.16371 4.73557 4 8.46848 4 13.0733C4 14.7704 4.56692 16.348 5.53818 17.6648C5.41059 19.1863 5.04343 21.3293 4 22.3731C4 22.3731 7.18831 21.9256 9.34812 20.6214C10.5478 21.127 11.8866 21.4111 13.2998 21.4111C18.4358 21.4111 22.5995 17.6782 22.5995 13.0733C22.5995 8.46848 18.4358 4.73557 13.2998 4.73557Z"
      fill="#4885ED"
    />
    <path
      d="M13.2994 19.027H13.5185L18.1828 8.28983H16.0541L14.2698 12.3906C13.8629 13.3141 13.4559 14.801 13.4559 14.801C13.4559 14.801 13.0333 13.2984 12.6263 12.3906L10.7951 8.28983H8.44733L13.2994 19.027Z"
      fill="white"
    />
  </svg>
);
