import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { loadSmallChat } from '@/components/SmallChat';

const AsideLink = ({
  Icon,
  name,
  url,
  className = '',
  onClick = undefined,
  setAsideHovered = undefined,
  setMinimized = undefined,
  currentOnline = 0,
  click = false,
}) => {
  const router = useRouter();

  let nextUrl;

  if (url.includes('analytics') && !url.includes('audio')) {
    nextUrl = `${url}/?host=&from=${dayjs()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}&to=${dayjs()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`;
  } else if (url.includes('analytics') && url.includes('audio')) {
    nextUrl = `${url}/?from=${dayjs()
      .subtract(13, 'days')
      .format('YYYY-MM-DD')}&to=${dayjs()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`;
  }

  const itemClassname = classNames('nav-item', className, {
    active:
      router.pathname === '/billing'
        ? router.pathname + '?by=' + router.query.by === url
        : router.pathname === url,
  });

  const redirectFunc = () => {
    if (onClick) {
      onClick();
    }
    if (setAsideHovered) {
      setAsideHovered(false);
    }
    if (setMinimized) {
      setMinimized(true);
    }
  };

  return (
    <li className={itemClassname} key={name} onClick={redirectFunc}>
      <Link
        href={url.includes('analytics') ? nextUrl : url}
        onClick={(e) => {
          if (click) {
            e.preventDefault();
            loadSmallChat();
          }
        }}
        className="nav-link"
      >
        {Icon()}
        <span
          className="lato-family-font"
          style={{ userSelect: 'none', marginRight: '5px' }}
        >
          {name}
        </span>
        {name === 'Realtime' && currentOnline > 0 && (
          <span>{' ' + `{${currentOnline}}`}</span>
        )}
      </Link>
    </li>
  );
};

AsideLink.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  classNames: PropTypes.string,
};

export default AsideLink;
