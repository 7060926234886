import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import NavItem from './AsideLink';

const AsideItemDropdown = ({
  minimized,
  name,
  Icon,
  subItems,
  setAsideHovered,
  setMinimized,
  url,
  openedDropdown,
  setOpenedDropdown,
}) => {
  const router = useRouter();
  // const isOpened = subItems.some(({ url }) => (router.pathname === url || url?.includes(router.pathname)) && router.pathname !== '/');

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const isOpened = subItems.some(
      ({ url }) =>
        (router.pathname === url || url?.includes(router.pathname)) &&
        router.pathname !== '/',
    );
    setOpened(isOpened);
  }, [router.pathname, router.query.by, router.query.tab, router.query.host]);

  useEffect(() => {
    if (openedDropdown && openedDropdown !== name) {
      setOpened(false);
    }
  }, [openedDropdown]);

  const handleMinimizedLinkClick = () => {
    if (minimized) {
      router.push(subItems[0].url);
    }
  };

  const itemClassName = classnames('nav-item with-sub', {
    show: opened && !minimized,
    active: router.pathname.includes(url) && minimized,
  });

  const handleClickOnTitle = () => {
    if (!minimized) {
      if (opened) {
        setOpened(false);
        setOpenedDropdown('');
      } else {
        setOpened(true);
        setOpenedDropdown(name);
      }
    }
  };

  return (
    <li className={itemClassName} key={name} onClick={handleClickOnTitle}>
      <div className={minimized ? 'nav-item_minimized' : 'nav-item_maximazed'}>
        <a className="nav-link" onClick={handleMinimizedLinkClick}>
          {Icon()}
          <span className="lato-family-font" style={{ userSelect: 'none' }}>
            {name}
          </span>
          <FontAwesomeIcon icon={faAngleDown} />
        </a>
        {!minimized && (
          <ul className="dropdown-new-items">
            {subItems.map(({ name, Icon, url, onClick, click }, index) => (
              <NavItem
                onClick={onClick}
                name={name}
                Icon={Icon}
                url={url}
                key={index}
                click={click}
                setAsideHovered={setAsideHovered}
                setMinimized={setMinimized}
              />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};

AsideItemDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default AsideItemDropdown;
