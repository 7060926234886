import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { Checkbox } from 'evergreen-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';

import LineNumbers from '@/components/LineNumbers';
import { copyTextOrElementContents } from '@/utils';

const AdsFileWarning = ({ showModal, setShowModal, fetchAdsData }) => {
  const { host } = useSelector(({ filter }) => filter);
  const { apiKey } = useSelector(({ session }) => session);
  const ads = useSelector(({ settingsPage }) => ({
    updated: settingsPage.profile.adsTxtUpdated,
    text: settingsPage.profile.adsTxtUrl,
    lines: settingsPage.profile.adsMissingLines,
  }));

  const newVuukleString = `vuukle.com, ${apiKey}, DIRECT, c8034d3da4547abf`;

  const [adsTxt, setAdsTxt] = useState('');
  const [textCopied, setTextCopied] = useState(false);
  const [showOnlyMissingLines, setShowOnlyMissingLines] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchIsEmpty, setSearchIsEmpty] = useState(false);

  const adsTxtEl = useRef();

  useEffect(() => {
    setTextCopied(false);
  }, [showOnlyMissingLines]);

  useEffect(() => {
    const adsLines = !showOnlyMissingLines
      ? adsTxt
          .split('\n')
          .filter((line) => line.toLowerCase().includes(searchValue))
      : ads &&
        ads.lines &&
        ads.lines.lines &&
        ads.lines.lines.length &&
        ads.lines.lines.filter((line) =>
          line.toLowerCase().includes(searchValue),
        );

    if (adsLines && adsLines.length) {
      setSearchIsEmpty(false);
    } else {
      setSearchIsEmpty(true);
    }
  }, [searchValue, showOnlyMissingLines]);

  useEffect(() => {
    if (showModal) {
      if (fetchAdsData && ads.text && ads.updated) {
        fetchAndSetAdsTxt();
      }

      if (ads.updated === false) {
        // If a link to an updated ads.txt file exists, fetch it's contents and display
        if (ads.text) {
          fetchAndSetAdsTxt();
        }
      }
    }
  }, [showModal, fetchAdsData, ads.updated, host]);

  const fetchAndSetAdsTxt = () => {
    axios
      .get(ads.text)
      .then((response) => {
        setAdsTxt(
          response.data.startsWith('<!DOCTYPE html><html')
            ? ''
            : response.data
                .split('\n')
                .map((adsEl) =>
                  ads.text.includes('common')
                    ? adsEl.includes('vuukle.com')
                      ? newVuukleString
                      : adsEl
                    : adsEl,
                )
                .join('\n'),
        );
      })
      .catch((err) => console.log(err));
  };

  const copyToClipboard = () => {
    const input = document.createElement('textarea');
    if (showOnlyMissingLines) {
      input.innerHTML = ads?.lines?.lines?.join('\n');
    } else {
      input.innerHTML = adsTxt;
    }
    document.body.appendChild(input);
    copyTextOrElementContents(input.innerHTML, input, () => {
      document.body.removeChild(input);
      setTextCopied(true);
    });
  };

  const downloadAdsTxt = () => {
    const textBlob = new Blob([adsTxt], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(
      textBlob,
      `vuukle-latest-adstxt-${dayjs().format('YYYY-MM-DD')}`,
    );
  };

  const downloadMissingAdsTxt = () => {
    const textBlob = new Blob([ads.lines.lines.join('\n')], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(
      textBlob,
      `vuukle-missinglines-only-adstxt-${dayjs().format('YYYY-MM-DD')}`,
    );
  };

  return (
    <>
      {adsTxt && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="custom-ads-text-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {ads.text
                ? `${
                    ads.lines.count > 0
                      ? 'Warning: missing Ads.txt detected!'
                      : ''
                  } ${
                    ads.lines.count > 0
                      ? `Amount of the missing lines: ${ads.lines.count}`
                      : 'Everything is up to date'
                  }`
                : 'Update our latest ads.txt lines'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ads.lines.count > 0
              ? 'Your ads.txt file is not up to date'
              : 'Everything is up to date'}
            , please contact &nbsp;
            <a href="mailto:support@vuukle.com">support@vuukle.com</a>&nbsp; for
            the latest entries{' '}
            <>
              or update your ads.txt file with the contents below
              <div className="d-flex flex-wrap justify-content-between mg-t-5 mg-b-5">
                <div className="d-flex">
                  <input
                    className="form-control mt-2 adsFileInput"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {searchValue ? (
                    <FontAwesomeIcon
                      icon={faClose}
                      width={15}
                      height={15}
                      color="#60657B"
                      style={{ marginTop: 18.5, marginLeft: -20 }}
                      onClick={() => setSearchValue('')}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <Checkbox
                  label="Show only missing lines"
                  style={{ margin: '10px 0', minWidth: 150 }}
                  onChange={(e) => setShowOnlyMissingLines(e.target.checked)}
                  checked={showOnlyMissingLines}
                />
              </div>
              {!showOnlyMissingLines ? (
                <pre className={`${searchIsEmpty ? 'd-none' : ''} mh-300`}>
                  <div className="d-flex">
                    <LineNumbers
                      content={
                        !searchValue
                          ? !showOnlyMissingLines
                            ? adsTxt
                            : ads?.lines?.lines?.join('\n')
                          : !showOnlyMissingLines
                          ? adsTxt
                              .split('\n')
                              .filter((line) =>
                                line.toLowerCase().includes(searchValue),
                              )
                              .join('\n')
                          : ads?.lines?.lines
                              ?.filter((line) =>
                                line.toLowerCase().includes(searchValue),
                              )
                              .join('\n')
                      }
                    />
                    <p ref={adsTxtEl} className="txt-m0-fsz13-lh165">
                      {!showOnlyMissingLines
                        ? adsTxt
                            .split('\n')
                            .filter((line) =>
                              line.toLowerCase().includes(searchValue),
                            )
                            .map((ads, index) => (
                              <span key={index} className="text-nowrap d-block">
                                {ads}
                              </span>
                            ))
                        : ads?.lines?.lines
                            ?.filter((line) =>
                              line.toLowerCase().includes(searchValue),
                            )
                            .map((ads, index) => (
                              <span key={index} className="text-nowrap d-block">
                                {ads}
                              </span>
                            ))}
                    </p>
                  </div>
                </pre>
              ) : ads &&
                ads.lines &&
                ads.lines.lines &&
                ads.lines.lines.length > 0 ? (
                <pre className={`${searchIsEmpty ? 'd-none' : ''} mh-300`}>
                  <div className="d-flex">
                    <LineNumbers
                      content={
                        !searchValue
                          ? !showOnlyMissingLines
                            ? adsTxt
                            : ads?.lines?.lines?.join('\n')
                          : !showOnlyMissingLines
                          ? adsTxt
                              .split('\n')
                              .filter((line) =>
                                line.toLowerCase().includes(searchValue),
                              )
                              .join('\n')
                          : ads?.lines?.lines
                              ?.filter((line) =>
                                line.toLowerCase().includes(searchValue),
                              )
                              .join('\n')
                      }
                    />
                    <p ref={adsTxtEl} className="txt-m0-fsz13-lh165">
                      {!showOnlyMissingLines
                        ? adsTxt
                            .split('\n')
                            .filter((line) =>
                              line.toLowerCase().includes(searchValue),
                            )
                            .map((ads, index) => (
                              <span key={index} className="text-nowrap d-block">
                                {ads}
                              </span>
                            ))
                        : ads?.lines?.lines
                            ?.filter((line) =>
                              line.toLowerCase().includes(searchValue),
                            )
                            .map((ads, index) => (
                              <span key={index} className="text-nowrap d-block">
                                {ads}
                              </span>
                            ))}
                    </p>
                  </div>
                </pre>
              ) : (
                <h3>Everything is up to date</h3>
              )}
              {searchIsEmpty && (
                <div className="text-center">There is no data</div>
              )}
            </>
          </Modal.Body>
          {adsTxt && (
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={downloadAdsTxt}
                className="ads-txt-modal-button"
              >
                Download Full Ads.txt
              </Button>
              <Button
                disabled={!ads.lines?.lines?.length}
                variant="primary"
                onClick={downloadMissingAdsTxt}
                className="ads-txt-modal-button ads-txt-modal-button__middle"
              >
                Download Missing {window.innerWidth > 500 ? 'Lines' : ''}
              </Button>
              <Button
                variant={textCopied ? 'success' : 'primary'}
                onClick={copyToClipboard}
                disabled={
                  !showOnlyMissingLines ? !adsTxt : !ads?.lines?.lines?.length
                }
              >
                {textCopied ? 'Copied!' : 'Copy to clipboard'}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
};

export default AdsFileWarning;
