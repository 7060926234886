import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';

import PageTitle from './PageTitle';
import Aside from './Aside';
import Footer from './Footer';

class AsideLayout extends Component {
  constructor(props) {
    super(props);

    const { sessionStore } = props;

    this.currentOnline;

    if (sessionStore.isRealtimeEnabled) {
      this.createWebSocketConnection();
    }
  }

  createWebSocketConnection() {
    this.closeConnection();

    this.webSocket = new WebSocket(`wss://s1.vuukle.com:8443`);

    this.intervalId = window.setInterval(() => {
      if (this.checkConnection()) {
        const { filterStore, realtimeStore, sessionStore } = this.props;
        const { article } = realtimeStore;

        if (article.id) {
          const apiKeyOrHost = !filterStore.host
            ? { apikey: sessionStore.apiKey }
            : { host: filterStore.host };
          this.webSocket.send(
            JSON.stringify({
              type: 'stats',
              ...apiKeyOrHost,
              articleId: article.id,
            }),
          );
        } else if (filterStore.host && !article.id) {
          this.webSocket.send(
            JSON.stringify({
              type: 'stats',
              host: filterStore.host,
            }),
          );
        } else {
          this.webSocket.send(
            JSON.stringify({
              type: 'stats',
              host: sessionStore.sites[0],
            }),
          );
        }
      }
    }, 10000);

    window.addEventListener('beforeunload', () => this.webSocket.close());
    this.webSocket.addEventListener('message', (message) =>
      this.onReceiveMessage(message),
    );
    /** Logic to re-connect to websocket on connection dying */
  }

  onReceiveMessage(message) {
    // this.currentOnline = JSON.parse(message.data);
    this.setState({
      currentOnline: JSON.parse(message.data),
    });
  }

  closeConnection() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.webSocket) {
      this.webSocket.removeEventListener('message', this.onReceiveMessage);
      this.webSocket.removeEventListener('error', this.onErrorWebsocket);
      this.webSocket.removeEventListener('close', this.onCloseWebsocket);
      if (this.webSocket.readyState === 1) {
        this.webSocket.close(1000);
      }
    }
  }

  checkConnection() {
    if (this.webSocket && this.webSocket.readyState === 1) {
      return true;
    }
    return false;
  }

  componentWillUnmount = () => {
    this.closeConnection();
  };

  render() {
    return (
      <div className="d-flex flex-column ht-100v aside_new">
        <PageTitle />
        <Aside currentOnline={this.state?.currentOnline} />
        <div
          className={`${
            this.props.router.pathname.includes('settings') &&
            this.props.router.query.tab === '0'
              ? 'h-100'
              : ''
          } ${
            this.props.router.pathname.includes('realtime')
              ? 'custom-height-rt-page'
              : ''
          } ${
            this.props.router.pathname.includes('integration') ||
            this.props.router.pathname.includes('adblock-management') ||
            this.props.router.pathname.includes('builder')
              ? 'content-100'
              : ''
          } content pd-0 d-flex flex-column aside_right ${
            this.props.withFirefoxScrollbar !== false ? '' : ''
          } ${
            (this.props.router.pathname === '/analytics/comments' ||
              this.props.router.pathname === '/analytics/emotes' ||
              this.props.router.pathname === '/analytics/shares') &&
            'analytics-layout_comments'
          } ${
            this.props.router.pathname === '/cloud-messaging' &&
            'overflow-hidden'
          }`}
        >
          {this.props.children}
          {this.props.router.pathname !== '/' && <Footer />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, filter, realtime }) => {
  return {
    sessionStore: session,
    filterStore: filter,
    realtimeStore: realtime,
  };
};

export default connect(mapStateToProps)(withRouter(AsideLayout));
