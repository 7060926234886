import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import AdsFileWarning from '@/components/AdsFileWarning';
import { adsTextMissingLinesRequest } from '@/services/actions/settings/profile';
import { getAdminProfileRequest } from '@/services/actions/settings';

const AdsTxt = ({ showWhenNoMissingLines = false }) => {
  const { sites } = useSelector(({ session }) => session);
  const ads = useSelector(({ settingsPage }) => ({
    lines: settingsPage.profile.adsMissingLines,
    loading: settingsPage.profile.loadingAds,
    text: settingsPage.profile.adsTxtUrl,
  }));

  const router = useRouter();
  const { query } = router;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [fetchAdsData, setFetchAdsData] = useState(false);

  useEffect(() => {
    if (!ads.loading) {
      dispatch(adsTextMissingLinesRequest(query.host || sites[0]));
    }
    if (!ads.text) {
      dispatch(getAdminProfileRequest());
    }
  }, [query.host]);

  const showAdsModal = () => {
    setFetchAdsData(true);
    setShowModal(true);
  };

  return (
    <>
      <AdsFileWarning
        showModal={showModal}
        setShowModal={setShowModal}
        fetchAdsData={fetchAdsData}
        setFetchAdsData={setFetchAdsData}
      />
      {(ads.lines.count > 0 || showWhenNoMissingLines) && (
        <button
          className={`new-button ml-auto mr-2 h-30 ${
            ads.lines.count > 0 ? 'new-bg-red' : ''
          }`}
          onClick={showAdsModal}
        >
          <span className="text-truncate">
            {ads.lines.count > 0
              ? `${ads.lines.count} ads.txt lines missing`
              : 'Ads.TXT'}
          </span>
        </button>
      )}
    </>
  );
};

export default AdsTxt;
